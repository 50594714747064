@import 'variables';

html {
	font-size: 100%;
	height: 100%;
	overflow-x: hidden;
	margin: 0px;
	padding: 0px;
	touch-action: manipulation;
  }
  
  body {
	font-size: 16px;
	font-family: "Poppins", serif;
	width: 100%;
	height: 100%;
	margin: 0;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	word-wrap: break-word;
	color: #333;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  ul,
  span,
  li,
  img,
  inpot,
  button {
	margin: 0;
	padding: 0;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
	line-height: 1.5;
	font-weight: inherit;
  }
  
  h1,
  h2,
  h3 {
	font-family: "Poppins", cursive;
  }
  
  p {
	line-height: 1.6;
	font-size: 1em;
	font-weight: 400;
	color: #777;
  }
  
  h1 {
	font-size: 4em;
	line-height: 1;
  }
  h2 {
	font-size: 2.2em;
	line-height: 1.1;
  }
  h3 {
	font-size: 1.8em;
  }
  h4 {
	font-size: 1.3em;
  }
  h5 {
	font-size: 1em;
  }
  h6 {
	font-size: 0.9em;
	letter-spacing: 1px;
  }
  
  a,
  button {
	display: inline-block;
	text-decoration: none;
	color: inherit;
	line-height: 1;
	-webkit-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
  }
  
  a:focus,
  a:active,
  a:hover,
  button:focus,
  button:active,
  button:hover,
  a b.light-color:hover {
	text-decoration: none;
	color: $mainClr;
  }
  
  b {
	font-weight: 600;
  }
  
  img {
	max-width: 100%;
  }
  
  li {
	list-style: none;
	display: inline-block;
  }
  
  span {
	display: inline-block;
  }
  
  button {
	outline: 0;
	border: 0;
	background: none;
	cursor: pointer;
  }
  
  .icon {
	font-size: 1.1em;
	display: inline-block;
	line-height: inherit;
  }
  
  [class^="icon-"]:before,
  [class*=" icon-"]:before {
	line-height: inherit;
  }
  
  b.max-bold {
	font-weight: 700;
  }
  
  a {
	color: $secClr;
	text-decoration: none;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
  }
  a:hover,
  a:focus {
	color: $mainClr;
	text-decoration: none;
  }
  
  .bg-green {
	background-color: $greenLight;
  }

  .bg-green-dark {
	background-color: $mainGreen;
  }
  
  .bg-blue {
	background-color: #deebf7;
  }
  
  .bg-silver {
	background-color: #e7e7e7;
  }
  
  .project img {
	border: 1px solid #d7d7d7;
	padding: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
	background-color: #fff;
  }
  
  /* SECTIONS */
  .section {
	padding: 100px 0 50px;
	border-bottom: 1px solid #ddd;
  }
  
  .section .heading {
	margin-bottom: 60px;
  }
  
  .section .heading:before {
	content: "";
	height: 10px;
	width: 50px;
	border-radius: 5px;
	margin-bottom: 20px;
	display: block;
	background: $mainClr;
  }
  
  .margin-t-10 {
	margin-top: 10px;
  }
  
  .margin-b-30 {
	margin-bottom: 30px;
  }
  .margin-b-50 {
	margin-bottom: 50px;
  }
  .margin-tb-30 {
	margin-top: 30px;
	margin-bottom: 30px;
  }
  
  .font-green {
	color: $mainGreen;
  }
  
  .font-semi-white {
	color: #ddd;
  }
  
  .font-lite-black {
	color: #999;
  }
  .bb-green {
	border-bottom: 4px solid $mainClr;
  }
  
  /* HEADER */
  .header-section {
	padding: 50px 0;
	position: relative;
	background-size: cover;
	z-index: 1;
	background-image: url(../assets/images/bg-content.jpg);
  }
  .header-section:before {
	content: "";
	z-index: -1;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.2);
  }
  .header-section .info {
	margin-bottom: 10px;
  }
  .header-section .info .icon {
	float: left;
	font-size: 26px;
  }
  .header-section .info .right-area {
	margin-left: 45px;
  }
  .header-section .info-location h5 {
	margin-top: 6px;
	position: relative;
	top: 8px;
	left: -10px;
  }
  
  /* INTRO */
  .intro {
	color: #fff;
  }
  
  .intro .profile-img {
	height: 160px;
	width: 160px;
	border-radius: 100px;
	margin: 0 auto;
	overflow: hidden;
	border: 4px solid #fff;
  }
  
  .intro .information > li {
	display: block;
	margin: 5px 0;
  }
  
  .intro .social-icons > li > a,
  .intro .social-icons > li > button {
	height: 50px;
	line-height: 50px;
	width: 50px;
	border-radius: 100px;
	overflow: hidden;
	z-index: 1;
	position: relative;
	margin: 0 5px 5px;
	text-align: center;
	background: $darkGreen;
	color: #fff;
	font-size: 22px;
  }
  
  .intro .social-icons > li > a:after,
  .intro .social-icons > li > button:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	z-index: -1;
	border-radius: 100px;
	background: $mainClr;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
	-webkit-transform: scale(0);
	transform: scale(0);
  }
  
  .intro .social-icons > li > a:hover:after,
  .intro .social-icons > li > button:hover:after {
	-webkit-transform: scale(1);
	transform: scale(1);
  }
  
  .intro .downlad-btn {
	padding: 15px 30px;
	border-radius: 4px;
	float: right;
	margin-top: 30px;
	box-shadow: inset 0 0px 4px rgba(0, 0, 0, 0.3);
	background: $mainClr;
	color: #fff;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
  }
  
  .intro .downlad-btn:hover {
	-webkit-transform: translateY(-4px);
	transform: translateY(-4px);
  }
  
  .btn-article {
	background: #53b713;
	color: #fff;
	padding: 15px 35px 15px 35px;
  }
  
  .btn-article:hover {
	background: $mainClr;
	color: #fff;
  }
  
  .radial-progress {
	position: relative;
	max-width: 200px;
	margin: 0 auto;
  }
  
  .radial-progress .progressbar-text {
	font-size: 2.2em;
	font-weight: 500;
	padding-bottom: 25px !important;
	color: #333 !important;
  }
  
  .radial-progress .progressbar-text:after {
	content: "%";
  }
  
  .radial-progress .progress-title {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	letter-spacing: 0;
	text-align: center;
	transform: translate(-50%, 15px);
	color: #777;
  }
  
  .experience .list > li {
	display: block;
	position: relative;
	margin-bottom: 10px;
	padding-left: 20px;
  }
  
  .experience .list > li:before {
	content: "";
	position: absolute;
	top: 50%;
	left: 0;
	height: 10px;
	width: 10px;
	margin-top: -5px;
	display: block;
	border-radius: 10px;
	border: 2px solid #555;
	opacity: 0.4;
  }
  
  .portfolio-section {
	position: relative;
  }
  .portfolio-section .portfolioFilter {
	margin-top: 60px;
	color: #666;
  }
  .portfolio-section .portfolioFilter a {
	margin: 10px 10px 0 0;
  }
  .portfolio-section .portfolioFilter a.current {
	color: $mainClr;
  }
  .portfolio-section .portfolioContainer {
	margin-left: -15px;
	margin-right: -15px;
  }
  .portfolio-section .p-item {
	width: 25%;
	padding: 0 7.5px 0px;
	margin-top: 15px;
  }
  .portfolio-section .p-item a {
	display: inline-block;
	position: relative;
  }
  
  .education-wrapper {
	position: relative;
  }
  .education-wrapper:after {
	content: "";
	position: absolute;
	top: 8px;
	bottom: 0;
	left: -51px;
	width: 2px;
	background: #ccc;
  }
  .education-wrapper .education {
	position: relative;
  }
  .education-wrapper .education:after {
	content: "";
	position: absolute;
	top: 8px;
	left: -58px;
	width: 16px;
	height: 16px;
	border-radius: 10px;
	z-index: 1;
	box-shadow: 0 0 0 8px rgba(93, 202, 253, 0.4);
	background: #5b9bd5;
  }
  
  .note-section {
	padding: 50px 0;
	position: relative;
	text-align: center;
	z-index: 1;
  }
  .note-section:before {
	content: "";
	z-index: -1;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
  }
  .note-section .icon {
	margin-bottom: 20px;
	height: 40px;
	width: 40px;
	line-height: 40px;
	border-radius: 30px;
	background: $mainClr;
	color: #fff;
  }
  
  .quoto-section {
	padding: 80px 0;
	position: relative;
	background-size: cover;
	text-align: center;
	z-index: 1;
	color: #fff;
  }
  
  .quoto-section:before {
	content: "";
	z-index: -1;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.8);
  }
  
  .quoto-section .icon {
	margin-bottom: 20px;
	height: 40px;
	width: 40px;
	line-height: 40px;
	border-radius: 30px;
	background: $mainClr;
	color: #fff;
  }
  
  .copyright {
	text-align: center;
	padding: 50px 0;
	background: #0f3e6a;
	color: #fff;
  }
  
  /* scroll to top */
  #toTop {
    width: 100px;
    text-align: center;
    padding: 5px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
    display: none;
    font-size: 11px;
    z-index: 999;
}
  
.bg-center {
	text-align: center;
	margin:auto;
}

  /* ---------------------------------
  STANDARDS
  --------------------------------- */
  .mt0 {
	margin-top: 0px !important;
  }
  .mt2 {
	margin-top: 2px !important;
  }
  .mt3 {
	margin-top: 3px !important;
  }
  .mt5 {
	margin-top: 5px !important;
  }
  .mt10 {
	margin-top: 10px !important;
  }
  .mt15 {
	margin-top: 15px !important;
  }
  .mt20 {
	margin-top: 20px !important;
  }
  .mt30 {
	margin-top: 30px !important;
  }
  .mt40 {
	margin-top: 40px !important;
  }
  .mt50 {
	margin-top: 50px !important;
  }
  .mt55 {
	margin-top: 55px !important;
  }
  .mt60 {
	margin-top: 60px !important;
  }
  .mt100 {
	margin-top: 100px !important;
  }
  
  .mb0 {
	margin-bottom: 0px !important;
  }
  .mb2 {
	margin-bottom: 2px !important;
  }
  .mb5 {
	margin-bottom: 5px !important;
  }
  .mb10 {
	margin-bottom: 10px !important;
  }
  .mb15 {
	margin-bottom: 15px !important;
  }
  .mb20 {
	margin-bottom: 20px !important;
  }
  .mb25 {
	margin-bottom: 25px !important;
  }
  .mb30 {
	margin-bottom: 30px !important;
  }
  .mb40 {
	margin-bottom: 40px !important;
  }
  .mb50 {
	margin-bottom: 50px !important;
  }
  
  .ml0 {
	margin-left: 0px !important;
  }
  .ml5 {
	margin-left: 5px !important;
  }
  .ml10 {
	margin-left: 10px !important;
  }
  .ml15 {
	margin-left: 15px !important;
  }
  .ml20 {
	margin-left: 20px !important;
  }
  .ml30 {
	margin-left: 30px !important;
  }
  .ml40 {
	margin-left: 40px !important;
  }
  .ml50 {
	margin-left: 50px !important;
  }
  .ml70 {
	margin-left: 70px !important;
  }
  .ml80 {
	margin-left: 80px !important;
  }
  .ml150 {
	margin-left: 150px !important;
  }
  .ml160 {
	margin-left: 160px !important;
  }
  .ml170 {
	margin-left: 170px !important;
  }
  
  .ml-10 {
	margin-left: -10px !important;
  }
  
  .mr0 {
	margin-right: 0px !important;
  }
  .mr5 {
	margin-right: 5px !important;
  }
  .mr10 {
	margin-right: 10px !important;
  }
  .mr15 {
	margin-right: 15px !important;
  }
  .mr20 {
	margin-right: 20px !important;
  }
  .mr30 {
	margin-right: 30px !important;
  }
  .mr40 {
	margin-right: 40px !important;
  }
  .mr50 {
	margin-right: 50px !important;
  }
  .mr70 {
	margin-right: 70px !important;
  }
  
  .p10 {
	padding: 10px;
  }
  .p20 {
	padding: 20px;
  }
  .p20i {
	padding: 20px !important;
  }
  
  .pt0 {
	padding-top: 0px !important;
  }
  .pt2 {
	padding-top: 2px !important;
  }
  .pt3 {
	padding-top: 3px !important;
  }
  .pt5 {
	padding-top: 5px !important;
  }
  .pt6 {
	padding-top: 6px !important;
  }
  .pt7 {
	padding-top: 7px !important;
  }
  .pt8 {
	padding-top: 8px !important;
  }
  .pt10 {
	padding-top: 10px !important;
  }
  .pt12 {
	padding-top: 12px !important;
  }
  .pt13 {
	padding-top: 13px !important;
  }
  .pt15 {
	padding-top: 15px !important;
  }
  .pt17 {
	padding-top: 17px !important;
  }
  .pt20 {
	padding-top: 20px !important;
  }
  .pt30 {
	padding-top: 30px !important;
  }
  .pt40 {
	padding-top: 40px !important;
  }
  .pt50 {
	padding-top: 50px !important;
  }
  
  .pb0 {
	padding-bottom: 0px !important;
  }
  .pb2 {
	padding-bottom: 2px !important;
  }
  .pb5 {
	padding-bottom: 5px !important;
  }
  .pb10 {
	padding-bottom: 10px !important;
  }
  .pb15 {
	padding-bottom: 15px !important;
  }
  .pb20 {
	padding-bottom: 20px !important;
  }
  .pb30 {
	padding-bottom: 30px !important;
  }
  .pb40 {
	padding-bottom: 40px !important;
  }
  .pb50 {
	padding-bottom: 50px !important;
  }
  
  .pl0 {
	padding-left: 0px !important;
  }
  .pl5 {
	padding-left: 5px !important;
  }
  .pl2 {
	padding-left: 2px !important;
  }
  .pl3 {
	padding-left: 3px !important;
  }
  .pl10 {
	padding-left: 10px !important;
  }
  .pl20 {
	padding-left: 20px !important;
  }
  .pl30 {
	padding-left: 30px !important;
  }
  .pl40 {
	padding-left: 40px !important;
  }
  .pl50 {
	padding-left: 50px !important;
  }
  .pl60 {
	padding-left: 60px !important;
  }
  .pl-10 {
	padding-left: -10px !important;
  }
  .ml-10 {
	margin-left: -10px !important;
  }
  
  .pr0 {
	padding-right: 0px !important;
  }
  .pr10 {
	padding-right: 10px !important;
  }
  .pr15 {
	padding-right: 15px !important;
  }
  .pr18 {
	padding-right: 18px !important;
  }
  .pr20 {
	padding-right: 20px !important;
  }
  .pr30 {
	padding-right: 30px !important;
  }
  .pr40 {
	padding-right: 40px !important;
  }
  .pr50 {
	padding-right: 50px !important;
  }
  .pr55 {
	padding-right: 55px !important;
  }
  
  .f10 {
	font-size: 10px !important;
  }
  .f11 {
	font-size: 11px !important;
  }
  .f12 {
	font-size: 12px !important;
  }
  .f13 {
	font-size: 13px !important;
  }
  .f14 {
	font-size: 14px !important;
  }
  .f15 {
	font-size: 15px !important;
  }
  .f16 {
	font-size: 16px !important;
  }
  .f18 {
	font-size: 18px !important;
  }
  .f20 {
	font-size: 20px !important;
  }
  .f22 {
	font-size: 22px !important;
  }
  .f24 {
	font-size: 24px !important;
  }
  .f28 {
	font-size: 28px !important;
  }
  .f36 {
	font-size: 36px !important;
  }
  
  .w50 {
	width: 50px !important;
  }
  .w80 {
	width: 80px !important;
  }
  .w100 {
	width: 100px !important;
  }
  .w120 {
	width: 120px !important;
  }
  .w140 {
	width: 140px !important;
  }
  .w150 {
	width: 150px !important;
  }
  .w160 {
	width: 160px !important;
  }
  .w190 {
	width: 190px !important;
  }
  .w200 {
	width: 200px !important;
  }
  .w250 {
	width: 250px !important;
  }
  .w270 {
	width: 270px !important;
  }
  .w350 {
	width: 350px !important;
  }
  .w490 {
	width: 490px !important;
  }
  .w500 {
	width: 500px !important;
  }
  .w600 {
	width: 600px !important;
  }
  .w700 {
	width: 700px !important;
  }
  
  .uc {
	text-transform: uppercase;
  }
  
  @import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700%7CAllura");
  
  /* ---------------------------------
  MEDIA QUERIES
  --------------------------------- */
  @media (max-width: 1024px) {
	.intro .profile-img {
	  margin-bottom: 20px;
	}
	.intro .social-icons {
	  margin-top: 10px;
	  margin-bottom: 10px;
	  margin-left: 10px;
	}
  }
  
  @media only screen and (max-width: 992px) {
	.education-wrapper:after,
	.education-wrapper .education:after {
	  display: none;
	}
  }
  
  @media (max-width: 768px) {
	.intro .profile-img {
	  margin-bottom: 10px;
	  text-align: center;
	}
	.intro .profile-info h2,
	.intro .profile-info h4 {
	  text-align: left;
	}
  }
  
  @media only screen and (max-width: 767px) {
	p {
	  line-height: 1.4;
	}
	h1 {
	  font-size: 3em;
	}
	h2 {
	  font-size: 2em;
	}
	h3 {
	  font-size: 1.8em;
	}
	.section {
	  padding: 50px 0 0px;
	}
	.section .heading {
	  margin-bottom: 30px;
	}
  
	.intro .profile-info h2,
	.intro .profile-info h4 {
	  text-align: center;
	}
	.header-section .info-location {
	  text-align: center;
	}
	.header-section .ion-android-pin {
	  display: none;
	}
  
	.portfolio-section .portfolioFilter {
	  margin-top: 0px;
	  margin-bottom: 20px;
	}
	.portfolio-section .p-item {
	  width: 50%;
	}
  }
  
  @media only screen and (max-width: 575px) {
	body {
	  font-size: 14px;
	}
	.portfolio-section .p-item {
	  width: 100%;
	}
  }
  
  @media only screen and (max-width: 480px) {
	.intro .profile-img {
	  margin-bottom: 22px;
	}
	.intro .social-icons {
	  margin-top: 10px;
	  margin-bottom: 10px;
	  margin-left: 0;
	}
	.intro .profile-info h2,
	.intro .profile-info h4 {
	  text-align: center;
	}
  }
  
  .scroll {
	opacity: 0.3;
	background-color: aqua;
	width: 40px;
	height: 40px;
	position: fixed;
	bottom: 10px;
	right: 10px;
	border-radius: 5px;
	border: none;
  }
  
  .scroll:hover {
	opacity: 1;
  }
  
  .arrow-up {
	color: white;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -9px;
	margin-left: -5px;
  }
  
  button:focus {
	outline: none;
	cursor: pointer;
  }
  
  .button-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	margin-top: 10px;
  }
  
  .button-container .btn {
	margin-right: 10px;
	margin-bottom: 10px;
  }

  .text-underline {
  text-decoration: underline !important
  }